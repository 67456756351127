import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
  MegaphoneIcon,
  AcademicCapIcon,
  HashtagIcon,
  ChartBarIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import ReactGA from 'react-ga4';
import { useEffect } from "react";
ReactGA.initialize('G-HD90TEG5P3');
const features = [
  {
    name: "Administrative Task",
    description:
      "Scheduling and Calendar Management, Data Entry Filing, Travel Arrangements, Meeting Preparation, Communication, Handling Phone Calls, Employee Onboarding, HR Support",
    icon: DocumentTextIcon,
  },
  {
    name: "Sales And Lead Generation",
    description:
      "Prospecting new clients, Conducting product demonstrations, Creating sales presentations Handling customer inquiries Managing sales leads",
    icon: ChartBarIcon,
  },
  {
    name: "Social Media Management",
    description:
      "Schedule daily posts, Create engaging content, Respond to comments and messages, Maintain brand consistency, Research and use relevant hashtags",
    icon: HashtagIcon,
  },
  {
    name: "Consultancy",
    description:
      "Contact us today to schedule a consultation and discover how our virtual assistant consultancy services can help your business thrive",
    icon: AcademicCapIcon,
  },
  {
    name: "Brand content development and marketing",
    description: `Elevate your brand's presence with our comprehensive services in content development and marketing, designed to create engaging and impactful narratives that resonate with your target audience and drive business growth`,
    icon: MegaphoneIcon,
  },
];
const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
};

const Services = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  
  return (
    <>
      <Header />
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeInUp}
        // className="h-screen"
      >

      <div
        id="services"
        class="section relative pt-20 pb-8 md:pt-16 md:pb-0 bg-white"
      >
        <div class="xl:max-w-6xl mx-auto px-4">
          <header class="text-center mx-auto mb-12 lg:px-20">
            <h2 class="text-2xl leading-normal mb-2 mt-20 font-bold text-black">
              What We Do
            </h2>
            <p class="text-gray-500 leading-relaxed font-light text-xl mx-auto pb-2">
              Optimize Your Workflow with Expert Visual Assistance for Your
              Business.
            </p>
          </header>
          <div className="my-20">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6 new-visible">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div className="w-1/6 m-auto">
                        <span
                          className="inline-flex items-center justify-center rounded-md p-3 shadow-lg"
                          style={{ backgroundColor: "#3BC52B" }}
                        >
                          <feature.icon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-center text-gray-900">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-center text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      </motion.section>
      <Footer />
    </>
  );
};

export default Services;
