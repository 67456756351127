// src/Blog.js
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { fetchPosts } from "./services/api";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const truncateText = (text, wordLimit = 20) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};
const fadeInSide = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
};
export default function BlogLanding() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getPosts = async () => {
      try {
        const data = await fetchPosts();
        setPosts(data.slice(-3).reverse()); // Only keep the first 3 posts
        setLoading(false);
      } catch (err) {
        setError("Failed to load posts. Please refresh the page");
        setLoading(false);
      }
    };
  
    getPosts();
  }, []);

  if (loading) {
    return (
      <>
        <div className="flex justify-center items-center h-screen">
          <ClipLoader size={50} color={"#14BA05"} loading={loading} />
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className="text-center text-red-500 mt-40">Please Refresh the page</div>
      </>
    );
  }

  return (
    <>
      <div className="bg-white py-10 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              From the blog
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Learn how to grow your business with our expert advice.
            </p>
          </div>
          <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeInSide}
            // className="h-screen"
          >
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none md:grid-cols-2 lg:grid-cols-3">
              {posts.map((post) => (
                <article
                  key={post.id}
                  className="flex flex-col items-start justify-between"
                >
                
                  <div className="relative w-full">
                    <img
                      src={post.imageUrl}
                      alt=""
                      className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                    />
                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="max-w-xl">
                    <div className="mt-8 flex items-center gap-x-4 text-xs">
                      <time dateTime={post.datetime} className="text-gray-500">
                        {post.date}
                      </time>
                      <Link
                        to={`/blog/${post.slug}`}
                        className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                      >
                        {post.category.title}
                      </Link>
                    </div>
                    <Link to={`/blog/${post.slug}`}>
                      <div className="group relative">
                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                          <a href={post.href}>
                            <span className="absolute inset-0" />
                            <Link to={`/blog/${post.slug}`}>{post.title}</Link>
                          </a>
                        </h3>
                        <p
                          dangerouslySetInnerHTML={{
                              __html: truncateText(post.description)
                            }}
                          className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600"
                        />
                      </div>
                    </Link>
                    <div className="relative mt-8 flex items-center gap-x-4">
                      <img
                        src={post.author.imageUrl}
                        alt=""
                        className="h-10 w-10 rounded-full bg-gray-100"
                      />
                      <div className="text-sm leading-6">
                        <p className="font-semibold text-gray-900">
                          <a href={post.author.href}>
                            <span className="absolute inset-0" />
                            {post.author.name}
                          </a>
                        </p>
                        <p className="text-gray-600">{post.author.role}</p>
                      </div>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </motion.section>
        </div>
      </div>
    </>
  );
}
