import React, { useState } from "react";
import { motion } from "framer-motion";
import { AiFillCheckCircle } from "react-icons/ai"
import { ClipLoader } from 'react-spinners'; 

const backdropVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.3 } },
};

const modalVariants = {
  hidden: { opacity: 0, scale: 0.8, y: -50 },
  visible: { 
    opacity: 1, 
    scale: 1, 
    y: 0, 
    transition: { type: "spring", stiffness: 100, damping: 10 } 
  },
  exit: { opacity: 0, scale: 0.8, transition: { duration: 0.7 } },
};

const PopupModal = ({ onClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const response = await fetch("https://api.sender.net/v2/subscribers", {
        method: "POST",
        headers: {
          "Authorization": `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYTkxMmM3NzMzZjcyNzRjM2RhYTgxYTJmYzNmYWY1NWJhNDEwYzM2NDU1ZmI1MDYwNDViNTNmNGQ0YjQxYzg3NmYxZGM1NjBkZDA3NjYyZWUiLCJpYXQiOiIxNzM3MDMwMDg5Ljk4MTgyNCIsIm5iZiI6IjE3MzcwMzAwODkuOTgxODI2IiwiZXhwIjoiNDg5MDYzMDA4OS45ODAzNDMiLCJzdWIiOiI4NTgxNTQiLCJzY29wZXMiOltdfQ.j6dzwQP9dbWMkt2Vz274smXCHQsBmhgCFpwdVcVvHaCwF3K8l5pV-p08cZxnyxqpOQ-5Zd_l57GStrFp2_K3uQ`,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          email,
          firstname: name,
          groups: ["e9GXGJ"],
          trigger_automation: true,
        }),
      });

      if (response.ok) {
        setSuccess(true);
      } else {
        alert("Failed to subscribe. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
    
    setLoading(false);
  };

  return (
    <motion.div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      variants={backdropVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <motion.div
        className="rounded-lg shadow-lg md:flex w-[400px] sm:w-[500px] md:w-[800px] relative justify-around p-10 "
        variants={modalVariants}
        initial="hidden"
        style={{ backgroundColor: "#E3E2E2" }}
        animate="visible"
        exit="exit"
      >
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-2xl"
          onClick={onClose}
        >
          &times;
        </button>

        {success ? (
          <div className="text-center w-full flex flex-col items-center">
            <AiFillCheckCircle className="text-green-500 w-16 h-16 mb-4" />
            <h2 className="text-xl font-bold text-gray-800">Success!</h2>
            <p className="text-gray-600 mt-4 font-bold text-center">
              Your free resource is on its way! Please check your email (including the promotions/spam folder) for your download.
            </p>
            <button
              className="mt-6 bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        ) : (
          <>
            <img
              src="https://executivehaven.org/executivebook.png"
              alt="Free eBook"
              className="mx-auto w-[150px] md:w-2/5 h-auto object-cover rounded-l-lg"
            />

            <div className="md:w-1/2 p-6 flex flex-col justify-center">
              <h2 className="text-xl font-bold text-center text-gray-800">Get Your Free Report: Maximizing Productivity</h2>
              <p className="text-sm text-gray-600 text-center mt-2">
                Discover how virtual assistants can transform your business operations.
              </p>

              <form onSubmit={handleSubmit} className="mt-4">
                <div className="mb-4">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                    required
                  />
                </div>
                <motion.button
                  type="submit"
                  style={{ backgroundColor: "#3BC52B" }}
                  className="w-full text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center justify-center"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={loading}
                >
                  {loading ? <ClipLoader color="white" loading={loading} size={20}  /> : "Get My Free eBook"}
                </motion.button>
              </form>
            </div>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};

export default PopupModal;
