import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
ReactGA.initialize('G-HD90TEG5P3');
const faqs = [
  {
    question: "What services do you offer?",
    answer:
      "Click Here",
    link: true,
    address: '/services'
  },
  {
    question: "How do I get started with your Virtual Assistant services?",
    answer:
      "Click Here",
    link: true,
    address: '/contact'
  },
  {
    question: "What is your pricing structure?",
    link: false,
    answer:
      "Our team of team-savvy  Virtual Assistants, managed by industry experts in Finance, Tech, HR, and more, is at your service for just $10/hour or our unbeatable monthly package starting at $1200/month (160hours)",
  },
  {
    question: "What is your response time and availability?",
    link: false,
    answer:
      "Typically 8hrs per day",
  },
]

export default function Faq() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  
  return (
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-16 lg:px-8 lg:py-16">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <DisclosureButton className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" className="mt-2 pr-12">
                      {faq.link ? <Link to={faq.address} className="text-base leading-7 text-blue-600 hover:underline">{faq.answer}</Link>: <p className="text-base leading-7 text-gray-600">{faq.answer}</p>}
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
  )
}
