import { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";

export default function Affiliate() {
  const [partners, setPartners] = useState([]);
  const controls = useAnimation(); // Framer Motion controls for animation

  useEffect(() => {
    fetch("https://admin.executivehaven.org/api/all-partners/")
      .then((response) => response.json())
      .then((data) => setPartners(data))
      .catch((error) => console.error("Error fetching partners:", error));
  }, []);

  // Start the animation automatically when the component mounts
  useEffect(() => {
    controls.start({
      x: "-50%",
      transition: { repeat: Infinity, duration: 15, ease: "linear" },
    });
  }, [controls]);

  // Duplicate the partners array to create a seamless loop
  const duplicatedPartners = [...partners, ...partners];

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h2 className="text-lg font-semibold leading-8 text-gray-900">
            Our Trusted Affiliates
          </h2>
          <div className="overflow-hidden relative mt-10">
            {/* Gradient overlay for the left side */}
            <div
              className="absolute inset-y-0 left-0 w-32 bg-gradient-to-r from-white to-transparent z-10"
              style={{ pointerEvents: "none" }}
            ></div>
            {/* Gradient overlay for the right side */}
            <div
              className="absolute inset-y-0 right-0 w-32 bg-gradient-to-l from-white to-transparent z-10"
              style={{ pointerEvents: "none" }}
            ></div>
            <motion.div
              className="flex space-x-8"
              initial={{ x: 0 }} // Initial position
              animate={controls} // Use controls to manage animation
              style={{ display: "flex", whiteSpace: "nowrap" }}
              onHoverStart={() => controls.stop()} // Pause animation on hover
              onHoverEnd={() =>
                controls.start({
                  x: "-50%",
                  transition: { repeat: Infinity, duration: 20, ease: "linear" },
                })
              } // Resume animation when hover ends
            >
              {duplicatedPartners.map((partner, index) => (
                <img
                  key={index}
                  className="h-12 w-auto object-contain mx-4"
                  src={partner.logo}
                  alt="Partner Logo"
                />
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}